import { http } from '../http-common';

/**
 * SENT durumundaki bildirimleri listeler
 * Örnek response:
 * [
 *   {
 *     "id": 16,
 *     "message": "6 /-/ INFORMATION /-/ string /-/ string",
 *     "status": "SENT"
 *   },
 *   {
 *     "id": 18,
 *     "message": "8 /-/ INFORMATION /-/ string /-/ string",
 *     "status": "SENT"
 *   }
 * ]
 */
const getMeNotificationsSent = () => {
  return http.get('/announcements/notifications?status=SENT');
};

const getMeNotifications = () => {
  return http.get('/announcements/notifications');
};

/**
 * SSE ile bildirim dinleme fonksiyonu.
 * @param userId {string | number}
 * @param token  {string} - accessToken
 * @param onNotification (data:string) => void
 * @param onError (error:any) => void
 */


const listenNotificationsSSE = (userId: string | number,
  token: string,
  onNotification: (data: string) => void,
  onError?: (error: any) => void
) => {
  // SSE endpoint örneği: http://localhost:8082/notifications/sse/:userId?token=...
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8082';
  const url = `${baseUrl}/notifications/sse/${userId}?token=${token}`;

  const eventSource = new EventSource(url);

  // Backend "notification" event’i yolladığını varsayıyoruz
  eventSource.addEventListener('notification', (event) => {
    if (onNotification) {
      onNotification(event.data);
    }
  });

  eventSource.onerror = (err) => {
    console.error('SSE error:', err);
    eventSource.close();
    if (onError) {
      onError(err);
    }
  };

  return eventSource;
};

/**
 * Bildirimi "okundu" olarak işaretleme isteği
 * @param notificationId {string|number}
 * Örnek: POST /notifications/read body: { notificationId: ... }
 */
const markAsRead = (notificationId: any) => {
  return http.post(`/notifications/read?notificationId=${notificationId}`);
};

const NotificationService = {
  getMeNotificationsSent,
  listenNotificationsSSE,
  markAsRead,
  getMeNotifications
};

export default NotificationService;