import React, { useEffect, useState } from 'react';
import { Tabs, Button, Table, Tag, Space, Card } from 'antd';
import { Link } from 'react-router-dom';
import Statuses from '../../modules/Status/Statuses';
import { RoleList } from '../Role/RoleList';
import DiscountRate from '../DiscountRate/DiscountRate';
import ToolList from '../../components/Tools/ToolList';
import ParameterList from '../../components/Parameters/ParameterList';
import OfferingList from '../../components/Offerings/OfferingList';
import ProposalListWithDetails from '../../components/ProjectFollowUp/ProposalList';
import ProposalList from '../../components/Proposal/ProposalList';

const { TabPane } = Tabs;

const ProjectFollowUp: React.FC = () => {
  // "proposal.show" iznini kontrol etmek için permissions state
  const [permissions, setPermissions] = useState<string[]>([]);
  const [activeKey, setActiveKey] = useState('1');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  useEffect(() => {
    // permissions'ı localStorage'dan okuyup parse ediyoruz
    const storedPermissions = localStorage.getItem('permissions');
    if (storedPermissions) {
      try {
        setPermissions(JSON.parse(storedPermissions));
      } catch (err) {
        console.error('permissions parse hatası:', err);
      }
    }
    setIsLoading(false);
  }, []);

  return (
    <div className='mb-60px'>
      {isLoading ? (
        <div>Loading...</div>
      ) : permissions.includes('proposal.show') ? (
        // proposal.show izni varsa, sekmeli yapıyı göster
        <Tabs
          activeKey={activeKey}
          onChange={handleTabChange}
          className="custom-tabs"
          tabBarStyle={{ borderBottom: 'none' }}
        >
          <TabPane
            tab={
              <Button
                type={activeKey === '1' ? 'primary' : 'default'}
                className="d-button tab-button"
              >
                Teklifler
              </Button>
            }
            key="1"
          >
            <ProposalListWithDetails defaultStatusIds={[6, 10]} type={1} />
          </TabPane>

          <TabPane
            tab={
              <Button
                type={activeKey === '2' ? 'primary' : 'default'}
                className="d-button tab-button"
              >
                Planlama Talepleri
              </Button>
            }
            key="2"
          >
            <ProposalListWithDetails defaultStatusIds={[6, 5]} type={2} />
          </TabPane>

          <TabPane
            tab={
              <Button
                type={activeKey === '3' ? 'primary' : 'default'}
                className="d-button tab-button"
              >
                Rapor & Fatura
              </Button>
            }
            key="3"
          >
            <ProposalListWithDetails defaultStatusIds={[5]} type={3} />
          </TabPane>

          <TabPane
            tab={
              <Button
                type={activeKey === '4' ? 'primary' : 'default'}
                className="d-button tab-button"
              >
                Tamamlanan Projeler
              </Button>
            }
            key="4"
          >
            <ProposalListWithDetails defaultStatusIds={[4]} type={4} />
          </TabPane>

          <TabPane
            tab={
              <Button
                type={activeKey === '5' ? 'primary' : 'default'}
                className="d-button tab-button"
              >
                Ödemeler
              </Button>
            }
            key="5"
          >
            <ProposalListWithDetails defaultStatusIds={[4]} type={5} />
          </TabPane>
        </Tabs>
      ) : (
        // proposal.show izni yoksa, sadece ProposalList göster
        <ProposalList defaultStatusIds={[4, 5, 6]} type={2} />
      )}
    </div>
  );
};

export default ProjectFollowUp;