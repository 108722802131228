import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Button,
  Dropdown,
  Menu,
  Input,
  Row,
  Col,
  Tag,
  Typography,
  Card,
  Avatar,
  Modal,
  Form,
  InputNumber,
  Tooltip,
  Divider,
  Select,
} from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import ProposalService from '../../services/ProposalService';
import { capitalizeWords, formatDate, formatToTL, openNotification } from '../../utils';
import CompanyService from '../../services/CompanyService';
import StatusService from '../../modules/Status/Service/StatusService';
import tinycolor from 'tinycolor2';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import debounce from 'lodash/debounce';
import ProposalView from '../../components/Proposal/ProposalView';
import PaymentModal from '../../components/ProjectFollowUp/PaymentModal';

const { Text } = Typography;

/**
 * Bu component, teklifleri listeleyip filtreleyen
 * ve permission (proposal.show) varsa tüm teklifleri,
 * yoksa sadece kullanıcının tekliflerini çeken mantığa sahiptir.
 */
const ProposalListWithDetails: React.FC<any> = ({
  type = 1,
  defaultStatusIds,
}) => {
  const [proposals, setProposals] = useState<any[]>([]);
  const [filteredProposals, setFilteredProposals] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState<any>(null);
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  // Permission kontrolü için state
  const [permissions, setPermissions] = useState<string[]>([]);

  const navigate = useNavigate();
  const [form] = useForm();
  const logoCache = new Map();
  const pendingRequests = new Map();
  const [selectedProposalId, setSelectedProposalId] = useState<any>(null);
  const [isPlanningModalVisible, setIsPlanningModalVisible] = useState(false);
  const [selectedStatusIds, setSelectedStatusIds] = useState<number[]>([]);
  const { id } = useParams<{ id: string }>();
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [paymentModalType, setPaymentModalType] = useState<any>();
  const [hasFormError, setHasFormError] = useState(false);

  useEffect(() => {
    // permissions'ı localStorage'dan al
    const storedPermissions = localStorage.getItem('permissions');
    if (storedPermissions) {
      try {
        setPermissions(JSON.parse(storedPermissions));
      } catch (err) {
        console.error('Permissions parse hatası:', err);
      }
    }

    // Status'ları çek
    StatusService.getAll()
      .then((response: any) => {
        const filteredStatuses = response.data.filter((status: any) =>
          defaultStatusIds ? defaultStatusIds.includes(status.id) : response.data
        );
        setStatuses(filteredStatuses);
      })
      .catch((error) => console.error('Statüler yüklenirken hata oluştu:', error));

    // Proposals çek
    const params = {
      page: currentPage,
      size: pageSize,
      company: searchText,
      statuIds:
        selectedStatusIds.length > 0
          ? selectedStatusIds.join(',')
          : defaultStatusIds.join(','),
      accountId: id ?? '',
    };
    fetchProposals(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  /**
   * Teklifleri fetch eden fonksiyon.
   * Eğer permissions.includes('proposal.show') == true ise
   * tüm teklifleri çekecek (getAll),
   * aksi takdirde sadece kendine ait teklifleri (getMe).
   */
  const fetchProposals = async (params: any) => {
    logoCache.clear();
    pendingRequests.clear();

    // proposal.show iznine bak
    if (permissions.includes('proposal.show')) {
      // Tüm teklifleri getir
      ProposalService.getAll(params)
        .then(async (response: any) => {
          const enrichedProposals = await enrichProposalsWithLogos(
            response.data.content
          );
          setProposals(enrichedProposals);
          setFilteredProposals(enrichedProposals);
          setTotalItems(response.data.totalElements);
        })
        .catch((error) => {
          console.error('Teklif verileri alınırken hata oluştu:', error);
          openNotification('error', 'Hata', 'Teklif verileri alınırken hata oluştu.');
        });
    } else {
      // Kendi tekliflerini getir
      ProposalService.getMe(params)
        .then(async (response: any) => {
          const enrichedProposals = await enrichProposalsWithLogos(
            response.data.content
          );
          setProposals(enrichedProposals);
          setFilteredProposals(enrichedProposals);
          setTotalItems(response.data.totalElements);
        })
        .catch((error) => {
          console.error('Teklif verileri alınırken hata oluştu:', error);
          openNotification('error', 'Hata', 'Teklif verileri alınırken hata oluştu.');
        });
    }
  };

  /**
   * Firma logolarını asenkron şekilde zenginleştiren fonksiyon.
   */
  const enrichProposalsWithLogos = async (proposalArr: any[]) => {
    return Promise.all(
      proposalArr.map(async (proposal) => {
        const { company } = proposal;
        if (company.imageId) {
          if (logoCache.has(company.imageId)) {
            return {
              ...proposal,
              company: { ...company, logoUrl: logoCache.get(company.imageId) },
            };
          } else if (pendingRequests.has(company.imageId)) {
            await pendingRequests.get(company.imageId);
            return {
              ...proposal,
              company: { ...company, logoUrl: logoCache.get(company.imageId) },
            };
          } else {
            const logoPromise = CompanyService.getImage(company.imageId)
              .then((logoResponse) => {
                const logoUrl = logoResponse.data.preSignUrl;
                logoCache.set(company.imageId, logoUrl);
                pendingRequests.delete(company.imageId);
                return logoUrl;
              })
              .catch((error) => {
                console.error('Logo yüklenirken hata oluştu:', error);
                pendingRequests.delete(company.imageId);
                return null;
              });

            pendingRequests.set(company.imageId, logoPromise);
            const logoUrl = await logoPromise;
            return { ...proposal, company: { ...company, logoUrl } };
          }
        }
        return proposal;
      })
    );
  };

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case 'download':
        console.log('Teklif indir:', record);
        break;
      case 'revise':
        console.log('Teklifi revize et:', record);
        break;
      case 'applyDiscount':
        console.log('İndirim uygula:', record);
        setSelectedProposal(record);
        setIsDiscountModalVisible(true);
        break;
      case 'requestPlanningDate':
        setSelectedProposalId(record.id);
        setIsPlanningModalVisible(true);
        break;
      default:
        break;
    }
  };

  const openPaymentModal = (proposalId: number, type: any) => {
    setSelectedProposalId(proposalId);
    setIsPaymentModalVisible(true);
    setPaymentModalType(type);
  };

  const handleModalOk = async () => {
    if (selectedProposal && discountedPrice !== null) {
      try {
        await ProposalService.updatePrice(selectedProposal.id, {
          newTotalPrice: discountedPrice,
        });

        const params = {
          page: currentPage,
          size: pageSize,
          company: searchText,
          statuIds:
            selectedStatusIds.length > 0
              ? selectedStatusIds.join(',')
              : defaultStatusIds.join(','),
          accountId: id ?? '',
        };
        fetchProposals(params);

        setIsDiscountModalVisible(false);
        setDiscountedPrice(null);
        form.setFieldValue('discountedPrice', null);
        openNotification('success', 'Başarılı', 'Fiyat başarıyla güncellendi.');
      } catch (error) {
        console.error('Fiyat güncellenirken hata oluştu:', error);
        openNotification('error', 'Hata', 'Fiyat güncellenirken hata oluştu.');
      }
    }
  };

  const handleModalCancel = () => {
    setIsDiscountModalVisible(false);
    setDiscountedPrice(null);
    setIsPlanningModalVisible(false);
  };

  const getStatusNameById = (statusId: number) => {
    const status = statuses.find((st: any) => st.id === statusId);
    return status ? status.name : 'Bilinmiyor';
  };

  const getStatusColorById = (statusId: number) => {
    const status = statuses.find((st: any) => st.id === statusId);
    return status ? status.color : '#fdfdfd';
  };

  const renderAllAttributesTooltipContent = (attributes: any[]) => {
    const totalFiyat = attributes?.reduce(
      (acc: number, attr: any) =>
        acc +
        attr?.discounts?.reduce(
          (subAcc: number, d: any) => subAcc + d.price * d.quantity,
          0
        ),
      0
    );

    return (
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <Divider style={{ borderColor: '#fff' }} />
        {attributes?.map((attribute, index) => (
          <div key={index} style={{ marginBottom: '8px' }} className="mt-16px">
            <Text strong style={{ color: '#fff' }}>
              {attribute.attributeName}
            </Text>
            {attribute.discounts.map((discount: any, idx: number) => (
              <p key={idx}>
                {discount.quantity} adet - {formatToTL(discount.price)}
              </p>
            ))}
            {index < attributes.length - 1 && (
              <Divider style={{ borderColor: '#fff' }} />
            )}
          </div>
        ))}
        <Divider style={{ borderColor: '#fff' }} />
        <p>
          <strong>Toplam Fiyat:</strong> {formatToTL(totalFiyat)}
        </p>
      </div>
    );
  };

  const columns = [
    {
      title: 'Firma Adı',
      dataIndex: ['company', 'name'],
      key: 'companyName',
      render: (text: any, record: any) => {
        const displayText = text.length > 28 ? `${text.substring(0, 28)}...` : text;
        return (
          <div className="d-flex align-items-center">
            <div className="avatar-image-logo" style={{ marginRight: 10 }}>
              {record?.company?.logoUrl ? (
                <img
                  src={record?.company?.logoUrl}
                  alt={record?.company?.name}
                  className="avatar-image-logo"
                  style={{ borderRadius: '50%' }}
                />
              ) : (
                <Avatar
                  className="avatar-image-logo"
                  style={{ backgroundColor: '#f0f0f0', color: '#000' }}
                >
                  {record?.company?.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>

            <Tooltip title={text.toUpperCase()}>
              <span>{displayText.toUpperCase()}</span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Fiyat',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (price: number, record: any) => (
        <div className="price-tooltip">
          <span>{formatToTL(price)}</span>
          <Tooltip
            overlayStyle={{ maxWidth: '450px', overflowY: 'auto', maxHeight: '300px' }}
            title={renderAllAttributesTooltipContent(record.attributes)}
          >
            <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Kontrol Adresi',
      dataIndex: ['address', 'addressLine1'],
      key: 'controlAddress',
      width: 200,
      render: (text: any, record: any) => {
        const addressLine1 = capitalizeWords(record?.address?.addressLine1 || '');
        const city = capitalizeWords(record?.address?.city || '');
        let combinedText;
        if (addressLine1 !== '' && city !== '') {
          combinedText = `${addressLine1}, ${city}`;
        } else {
          combinedText = '-';
        }

        return combinedText && combinedText.length > 40 ? (
          <Tooltip title={combinedText}>
            <Text>{combinedText.slice(0, 40)}...</Text>
          </Tooltip>
        ) : (
          <Text>{combinedText || '-'}</Text>
        );
      },
    },
    {
      title: 'Teklif Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => <Text>{formatDate(date)}</Text>,
    },
    {
      title: 'Teklif No',
      dataIndex: 'proposalNumber',
      key: 'proposalNumber',
      render: (proposalNumber: string) => (
        <span style={{ color: 'blue' }}>{proposalNumber}</span>
      ),
    },
    {
      title: 'Statü',
      dataIndex: 'statusId',
      key: 'status',
      render: (statusId: number) => {
        const color = tinycolor(getStatusColorById(statusId));
        const transparentColor = color.setAlpha(0.1).toRgbString();
        const transparentColorBorder = color.setAlpha(0.5).toRgbString();

        return (
          <Tag
            style={{
              color: getStatusColorById(statusId),
              backgroundColor: transparentColor,
              borderColor: transparentColorBorder,
              borderRadius: '12px',
              padding: '5px 10px',
              fontSize: '14px',
              display: 'inline-block',
              minWidth: '100px',
              textAlign: 'center',
              fontWeight: 500,
            }}
          >
            {getStatusNameById(statusId)}
          </Tag>
        );
      },
    },
    {
      title: 'İşlemler',
      key: 'action',
      render: (_: any, record: any) => (
        <Dropdown
          overlay={
            <Menu onClick={({ key }) => handleMenuClick(key, record)}>
              {type === 1 && record.statusId !== 1 && record.statusId !== null && (
                <Menu.Item key="download">
                  <ProposalView proposalId={record.id}></ProposalView>
                </Menu.Item>
              )}
              {type === 2 && (
                <>
                  <Menu.Item
                    key="payment"
                    onClick={() => openPaymentModal(record.id, 'edit')}
                    disabled={record.paidAmount}
                  >
                    Ödeme Yap
                  </Menu.Item>
                  <Menu.Item
                    key="upload"
                    disabled={record.paidAmount == null}
                    onClick={() => openPaymentModal(record.id, 'view')}
                  >
                    Ödeme Görüntüle
                  </Menu.Item>
                </>
              )}
            </Menu>
          }
        >
          <Button type="primary" className="d-button dark-button">
            İşlemler
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchText(value);
      fetchProposals({
        page: currentPage,
        size: pageSize,
        company: value,
        statuIds:
          selectedStatusIds.length > 0
            ? selectedStatusIds.join(',')
            : defaultStatusIds.join(','),
        accountId: id ?? '',
      });
    }, 300),
    [currentPage, pageSize, selectedStatusIds, id, defaultStatusIds, permissions]
  );

  const handleStatusChange = (value: number[]) => {
    setSelectedStatusIds(value);
    const params = {
      page: currentPage,
      size: pageSize,
      company: searchText,
      statuIds:
        value.length > 0 ? value.join(',') : defaultStatusIds.join(','),
      accountId: id ?? '',
    };
    fetchProposals(params);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target?.value?.toLowerCase();
    setSearchText(searchValue);
    handleSearch(searchValue);
  };

  const onFieldsChange = () => {
    const errors = form.getFieldsError();
    const hasError = errors.some((field) => field.errors.length > 0);
    setHasFormError(hasError);
  };

  const handleDiscountChange = (value: number | null) => {
    if (!value) {
      setDiscountedPrice(selectedProposal.totalPrice);
      return;
    }
    const newDiscountedPrice = selectedProposal.totalPrice - value;
    setDiscountedPrice(newDiscountedPrice);
  };

  return (
    <div className="mb-120px">
      <Row justify="space-between" align="middle">
        <Col />
        <Col span={12}>
          <Row justify="end" className="mb-16px">
            <Select
              mode="multiple"
              placeholder="Statü seçin"
              style={{ width: 200, marginRight: '16px' }}
              onChange={handleStatusChange}
            >
              {statuses.map((status) => (
                <Select.Option key={status.id} value={status.id}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
            <Input
              placeholder="Ara"
              prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
              className="d-input d-input-form mr-16px d-input-filter"
              style={{ width: 200 }}
              onChange={handleInputChange}
              value={searchText}
            />
          </Row>
        </Col>
      </Row>
      <Table
        scroll={{ x: 768 }}
        columns={columns}
        dataSource={filteredProposals}
        rowKey="proposalNumber"
        pagination={{
          current: currentPage + 1,
          pageSize: pageSize,
          total: totalItems,
          onChange: (page, size) => {
            setCurrentPage(page - 1);
            setPageSize(size || 10);
          },
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30'],
        }}
      />

      {/* İndirim Modal */}
      <Modal
        title="İndirim Uygula"
        open={isDiscountModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        {selectedProposal && (
          <>
            <p>İndirimli fiyat teklifinizi hızlı bir şekilde hazırlayın.</p>

            <div style={{ marginBottom: 16 }}>
              <Text strong>Teklif Fiyatı</Text>
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {formatToTL(selectedProposal.totalPrice)}
              </div>
            </div>

            <Form layout="vertical" form={form} onFieldsChange={onFieldsChange}>
              <Form.Item
                label={`İndirim tutarı (Maks: ${formatToTL(
                  selectedProposal.maxDiscountPrice
                )})`}
                name="discountAmount"
                rules={[
                  {
                    validator: (_, value) => {
                      /**
                       * Koşullar:
                       * - null/undefined veya 0 => geçerli
                       * - 0 <= value <= maxDiscountPrice => geçerli
                       * - aksi halde hata
                       */
                      if (value === undefined || value === null) {
                        return Promise.resolve();
                      }
                      if (
                        typeof value === 'number' &&
                        value >= 0 &&
                        value <= selectedProposal.maxDiscountPrice
                      ) {
                        return Promise.resolve();
                      }
                      if (selectedProposal.maxDiscountPrice === 0) {
                        return Promise.reject(
                          new Error(`Daha fazla indirim uygulayamazsınız!`)
                        );
                      }
                      return Promise.reject(
                        new Error(
                          `İndirim tutarı 0 ile ${formatToTL(
                            selectedProposal.maxDiscountPrice
                          )} arasında olmalıdır!`
                        )
                      );
                    },
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  step={1}
                  precision={0}
                  style={{ width: '100%' }}
                  placeholder="İndirim Tutarı"
                  className="d-input d-input-form d-flex align-items-center"
                  onKeyDown={(event) => {
                    if (event.key === ',') {
                      event.preventDefault();
                    }
                  }}
                  onChange={(val) => handleDiscountChange(val)}
                />
              </Form.Item>

              <div style={{ marginTop: 16 }}>
                <Text strong>Yeni Teklif Tutarı</Text>
                <div
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#52c41a',
                    marginTop: '8px',
                  }}
                >
                  {discountedPrice !== null
                    ? formatToTL(discountedPrice)
                    : formatToTL(selectedProposal.totalPrice)}
                </div>
              </div>
            </Form>

            <div style={{ marginTop: 24, textAlign: 'center' }}>
              <Button
                type="primary"
                className="d-button dark-button width-auto p-20px"
                disabled={hasFormError || selectedProposal.isTotalPriceSet}
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => handleModalOk())
                    .catch((err) => {
                      console.log('Form error:', err);
                    });
                }}
              >
                İndirimli Teklifi Oluştur
              </Button>
            </div>
          </>
        )}
      </Modal>

      {/* Ödeme Modal */}
      {isPaymentModalVisible && selectedProposalId && (
        <PaymentModal
          visible={isPaymentModalVisible}
          onClose={() => setIsPaymentModalVisible(false)}
          proposalId={selectedProposalId}
          mode={paymentModalType}
        />
      )}
    </div>
  );
};

export default ProposalListWithDetails;