import React, { useEffect, useState } from 'react';
import { Badge, Popover, Row, Tooltip, Typography } from 'antd';
import { useNavigate } from 'react-router-dom'; // /all-notifications sayfasına yönlendirme için
import NotificationService from '../../services/NotificationService';

// Transition animasyonu için react-transition-group
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import {
  CheckCircleOutlined,
  // InfoCircleFilled,    <-- Kaldırıyoruz, type ikonu yok.
  // SyncOutlined,
  // CalendarFilled,
  // QuestionCircleFilled,
  CloseOutlined
} from '@ant-design/icons';

import NotifyIcon from './../../assets/icons/notify.svg';  // Bildirim ikonu (Badge üzerinde)
import './Notifications.css';
import NoData from '../../views/NoData';

const { Text } = Typography;

function Notifications() {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const navigate = useNavigate(); // /all-notifications sayfasına yönlendirme

  // Bildirimleri çeken fonksiyon
  const fetchNotifications = () => {
    NotificationService.getMeNotificationsSent()
      .then((res: any) => {
        if (Array.isArray(res.data)) {
          // Örnek item: { id:16, message:"6 /-/ INFORMATION /-/ baslik /-/ msg", status:"SENT" }
          const validList = res.data
            .map((item: any) => parseRawMessage(item.message, item.id))
            .filter(Boolean);
          setNotifications(validList);
        }
      })
      .catch((err: any) => {
        console.error('SENT bildirimler çekilirken hata:', err);
      });
  };

  // 1) İlk açılışta notification listesini çek
  useEffect(() => {
    fetchNotifications();
  }, []);

  // 2) SSE ile yeni bildirimleri dinle
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('id');
    if (!token || !userId) return;

    const es = NotificationService.listenNotificationsSSE(
      userId,
      token,
      (data: string) => {
        // data: "14 /-/ INFORMATION /-/ title /-/ message"
        console.log('Yeni bildirim SSE:', data);
        // Yeni veri geldiğinde tam listeyi tekrar çekmek için:
        if (data) {
          fetchNotifications();
        }
      },
      (err: any) => {
        console.error('SSE error:', err);
      }
    );

    return () => {
      es.close();
    };
  }, []);

  /**
   * Format bekleneni:
   * "6 /-/ INFORMATION /-/ string /-/ string"
   * Dilerseniz 5. parça ile tarih de parse edebilirsiniz.
   */
  function parseRawMessage(raw: string, fallbackId?: number): any | null {
    if (!raw || !raw.includes('/-/')) return null;
    const parts = raw.split('/-/');
    if (parts.length < 4) return null;

    return {
      announcementId: parts[0].trim() || fallbackId || Math.random().toString(),
      id: fallbackId || 0,
      type: parts[1].trim(),
      title: parts[2].trim(),
      message: parts[3].trim(),
      // Sizin senaryonuzda 5. parça tarih olabilir. Burada sabit bir tarih veriyoruz.
      date: '06/10/2024 14:00',
    };
  }

  // 3) “Okundu” isteği
  const handleMarkAsRead = (notifId: string | number) => {
    NotificationService.markAsRead(notifId)
      .then((res) => {
        if (res.status === 200) {
          // Listeden çıkar
          setNotifications((prev) => prev.filter((item) => item.id !== notifId));
        }
      })
      .catch((err) => {
        console.error('Okundu işaretleme hatası:', err);
      });
  };

  // Bildirim sayısı (9’dan fazlaysa "9+")
  const notificationCount = notifications.length > 9 ? '9+' : notifications.length;

  /**
   * Popover içeriği
   */
  const renderPopoverContent = () => {
    return (
      <div className="custom-notif-popover">
        {/* Üst başlık */}
        <div className="notif-header">
          <Text strong className="notif-header-title">Duyurularım</Text>
          <CloseOutlined
            style={{ fontSize: 16, cursor: 'pointer' }}
            onClick={() => setPopoverVisible(false)}
          />
        </div>

        {/* İçerik */}
        {notifications.length === 0 ? (
          <div style={{ textAlign: 'center', padding: 12 }}>
            <NoData />
          </div>
        ) : (
          <div className="notif-list-container">
            <TransitionGroup component={null}>
              {notifications.map((notif) => (
                <CSSTransition
                  key={notif.id}
                  timeout={300}
                  classNames="slide"
                >
                  <div className="notif-item">
                    {/* Üst satır: Type (yazı olarak) solda, Tarih ve Okundu ikonu sağda */}
                    <div className="notif-item-header-flex">
                      {/* Sol: Type metni */}
                      <Text strong style={{ fontSize: 14, color: '#000' }}>
                        {getTurkishType(notif.type)}
                      </Text>

                      {/* Sağ: tarih + okundu ikonu yan yana */}
                      <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                        <Text style={{ color: '#666', fontSize: 13 }}>
                          {notif.date}
                        </Text>
                        <Tooltip title="Okundu Olarak İşaretle">
                          <CheckCircleOutlined
                            onClick={() => handleMarkAsRead(notif.id)}
                            style={{ fontSize: 18, color: '#1890ff', cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </div>
                    </div>

                    {/* İkinci satır: Title (tooltip) */}
                    <Tooltip title={notif.title}>
                      <Text style={{ fontWeight: 700, color: '#000' }}>
                        {truncateText(notif.title, 100)}
                      </Text>
                    </Tooltip>
                    <br />
                    {/* Üçüncü satır: message (tooltip) */}
                    <Tooltip title={notif.message}>
                      <Text style={{ color: '#000' }}>
                        {truncateText(notif.message, 120)}
                      </Text>
                    </Tooltip>
                  </div>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
        )}

        {/* Alt kısım: Tüm duyurularım */}
        <div
          className="notif-footer"
          onClick={() => {
            setPopoverVisible(false);
            navigate('/all-notifications');
          }}
        >
          Tüm Duyurularım
        </div>
      </div>
    );
  };

  function getTurkishType(type: string) {
    switch (type) {
      case 'INFORMATION':
        return 'Bilgilendirme';
      case 'UPDATE':
        return 'Güncelleme';
      case 'EVENT':
        return 'Etkinlik';
      default:
        return 'Diğer';
    }
  }

  function truncateText(txt: string, maxLen: number) {
    if (!txt) return '';
    if (txt.length <= maxLen) return txt;
    return txt.substring(0, maxLen) + '...';
  }

  return (
    <Popover
      content={renderPopoverContent()}
      trigger="click"
      open={popoverVisible} // AntD v5
      onOpenChange={setPopoverVisible}
      placement="bottomRight"
      overlayClassName="custom-notif-overlay"
    >
      <Row className="notify-box" justify="center" align="middle">
        <Badge count={notificationCount} overflowCount={9}>
          <img
            src={NotifyIcon}
            alt="Bildirim"
            style={{ cursor: 'pointer', width: 24, height: 24 }}
          />
        </Badge>
      </Row>
    </Popover>
  );
}

export default Notifications;