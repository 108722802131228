import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Row,
  Typography,
  Card,
  Input,
  Popconfirm,
  Modal,
  Form,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AgreementService from "../../services/AgreementService";
import { formatDate, openNotification } from "../../utils";

// SunEditor importları
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const { Title } = Typography;

const AgreementList: React.FC = () => {
  const [agreements, setAgreements] = useState<any[]>([]);
  const [filteredAgreements, setFilteredAgreements] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Düzenleme modalı
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editingAgreement, setEditingAgreement] = useState<any | null>(null);

  // AntD form
  const [editForm] = Form.useForm();
  const navigate = useNavigate();

  // SunEditor içeriğini tutan state
  const [editorContent, setEditorContent] = useState<string>("");

  // 1) Sözleşmeleri yükle
  useEffect(() => {
    fetchAgreements();
  }, []);

  // 2) Arama filtrasyonu
  useEffect(() => {
    const filtered = agreements.filter((agreement) =>
      agreement.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredAgreements(filtered);
  }, [searchTerm, agreements]);

  const fetchAgreements = async () => {
    setLoading(true);
    try {
      AgreementService.getAllAgreements()
      .then((response: any) => {
        setAgreements(response.data);
      })
      .catch((error) => console.error("Sözleşmeler yüklenirken hata oluştu:", error));
    } catch (error) {
      openNotification("error", "Hata", "Sözleşmeler yüklenirken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  // Yeni sözleşme oluştur sayfasına
  const handleNavigateCreate = () => {
    navigate("/agreements/create");
  };

  // Düzenleme modalını aç
  const openEditModal = (record: any) => {
    setEditingAgreement(record);
    editForm.setFieldsValue({
      name: record.name,
    });
    setEditorContent(record.contractHtml || "");
    setEditModalVisible(true);
  };

  // Modal kapat
  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    setEditingAgreement(null);
    editForm.resetFields();
    setEditorContent("");
  };

  // Kaydet (submit) butonu
  const handleUpdateSubmit = async (values: any) => {
    if (!editingAgreement) return;

    try {
      // "editorContent" içinde SunEditor'un HTML içeriği var
      const requestBody = {
        ...editingAgreement,
        name: values.name,
        contractHtml: editorContent,
      };

      await AgreementService.updateAgreement(editingAgreement.id, requestBody);
      openNotification("success", "Başarılı", "Sözleşme güncellendi.");
      setEditModalVisible(false);
      fetchAgreements();
    } catch (error) {
      openNotification("error", "Hata", "Düzenleme sırasında hata oluştu.");
    }
  };

  // Tablo kolonları
  const columns = [
    {
      title: "Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Son Güncelleme Tarihi",
      dataIndex: "updateAt",
      key: "updateAt",
      render: (text: any) => <span>{formatDate(text)}</span>,
    },
    {
      title: "İşlemler",
      key: "actions",
      render: (_: any, record: any) => (
        <Row gutter={8}>
          <Button type="primary" className="d-button width-auto" onClick={() => openEditModal(record)}>
            Düzenle
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <Card className="br-30px">
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Title style={{ margin: 0 }} level={4}>
          Sözleşme Listesi
        </Title>
        <Row>
          <Input
            placeholder="Ara"
            prefix={<SearchOutlined style={{ color: "#b8b9bc" }} />}
            style={{ width: 200, marginRight: 16 }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {/* <Button type="primary" className="width-auto d-button" onClick={handleNavigateCreate}>
            Yeni Sözleşme Ekle
          </Button> */}
        </Row>
      </Row>

      <Table
        dataSource={filteredAgreements}
        columns={columns}
        rowKey="id"
        loading={loading}
      />

      {/* Düzenleme Modal */}
      <Modal
        title="Sözleşmeyi Düzenle"
        visible={editModalVisible}
        onCancel={handleEditModalCancel}
        footer={null}
        destroyOnClose
        width={900} // Editör genişliği için modalı biraz büyütebilirsiniz
      >
        <Form
          form={editForm}
          layout="vertical"
          onFinish={handleUpdateSubmit}
          initialValues={{ name: "" }}
        >
          <Form.Item
            label="Adı"
            name="name"
            rules={[{ required: true, message: "Lütfen sözleşme adını giriniz." }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Açıklama">
            <SunEditor
              setContents={editorContent}
              onChange={(value) => setEditorContent(value)}
              height="300px"
              setDefaultStyle="background-color: #F6F7FB"
              setOptions={{
                buttonList: [
                  ["undo", "redo"],
                  ["bold", "underline", "italic", "strike"],
                  ["fontColor", "hiliteColor", "align", "list", "indent", "outdent"],
                  ["table", "link", "image", "codeView"],
                ],
              }}
            />
          </Form.Item>

          <Row justify="center">
            {/* <Button style={{ marginRight: 8 }} onClick={handleEditModalCancel}>
              Vazgeç
            </Button> */}
            <Button type="primary" className="d-button width-auto" htmlType="submit">
              Kaydet
            </Button>
          </Row>
        </Form>
      </Modal>
    </Card>
  );
};

export default AgreementList;