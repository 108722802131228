import { notification } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/tr';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
export const openNotification = (type: NotificationType, status: string, description: string) => {
    const args = {
        message: status,
        description: description,
        duration: 2,
    };
    notification[type](args);
};

export function isAuthorized(requiredPermission: any) {
    let permissions = localStorage.getItem('permissions')
    return permissions?.includes(requiredPermission);
}

export function isFutureDate(dateString: string): boolean {
    const inputDate = dayjs(dateString);
    const now = dayjs();

    // Gelen tarih ve saat şimdiki zamandan büyük mü kontrol et
    return inputDate.isAfter(now);
}

export function formatTime(date: string): string {
    if (!date) {
        return '';
    }
    return dayjs(date).format('HH:mm');
}

export function formatDateString(date: string): string {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY');
}

export function formatDate(date: string): string {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY HH:mm');
}

export function getDateRangeDefault(): any {
    let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
        dayjs().add(-90, 'd'),
        dayjs()
    ];
    return dateRangeDefaultValue;
}

export function getCurrentMonthDateRange() {
    let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
        dayjs().add(-7, 'd'), dayjs()
    ];

    return dateRangeDefaultValue;
}

export function getCurrentMonthDateRangeNew() {

    let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
        dayjs().startOf('month'), dayjs()
    ];

    return dateRangeDefaultValue;
}

export function convertDateFormat(dateStr: any): string {
    return dayjs(dateStr, 'DD/MM/YYYY').format('YYYY-MM-DD');
}

export function filterOption(input: string, option: any): boolean {
    return option?.children.toLocaleLowerCase('tr').includes(input.toLocaleLowerCase('tr'));
}

export function formatToTL(value: number): string {
    const formattedValue = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
    }).format(value);

    return `${formattedValue.replace('₺', '').trim()} ₺`;
}


export function formatRelativeTime(date: string): string {
    dayjs.extend(relativeTime);
    dayjs.locale('tr');
    if (!date) {
        return '';
    }

    const inputDate = dayjs(date);
    const now = dayjs();

    return inputDate.from(now);
}

export const capitalizeWords = (str: any) => {
    if (!str) return '';

    let result = str.toLocaleLowerCase('tr-TR');

    result = result.replace(/(?:^|\s)(\S)/g, (match: string, firstChar: string) => {
        return match.replace(firstChar, firstChar.toLocaleUpperCase('tr-TR'));
    });

    return result;
};


export const capitalizeSentences = (str: any) => {
    if (!str) return '';
    let result = str.toLocaleLowerCase('tr-TR');
    result = result.charAt(0).toLocaleUpperCase('tr-TR') + result.slice(1);
    result = result.replace(/(\.\s*)(\w)/g, (match: any, separator: any, char: any) => {
        return separator + char.toLocaleLowerCase('tr-TR');
    });

    return result;
};

export const clearStorage = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenExpiredAt');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiredAt');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('email');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('id');
    localStorage.removeItem('roles');
    localStorage.removeItem('permissions');
    localStorage.removeItem('profilePhotoBase64');
    localStorage.removeItem('profilePhoto');
};