import { Button, Form, Input, Checkbox, Divider, Row, Col, Typography } from 'antd';
import { useEffect, useState } from 'react';
import PermissionService from '../../services/PermissonService';
import RoleService from '../../services/RoleService';
import { IPermissionResponseData } from '../../types/Permission';
import { IRoleResponseData } from '../../types/Role';
import { openNotification } from '../../utils';

const { Title } = Typography;

interface RoleEditProps {
  id: number;
  onClose: () => void;
}

/**
 * Grup adlarını Türkçe'ye çevirir.
 */
const translateGroup = (group: string): string => {
  switch (group) {
    case 'roles':
      return 'Rol';
    case 'permission':
      return 'Yetki';
    case 'attribute':
      return 'Parametre';
    case 'company':
      return 'Firma';
    case 'settings':
      return 'Ayarlar';
    case 'proposal':
      return 'Teklifler';
    case 'ticket':
      return 'Destek';
    default:
      return group; // Tanımsız grup için orijinal metni döndür
  }
};

/**
 * Eylem adlarını Türkçe'ye çevirir.
 */
const translateAction = (action: string): string => {
  switch (action) {
    case 'create':
      return 'Ekleme';
    case 'delete':
      return 'Silme';
    case 'update':
      return 'Düzenleme';
    case 'list':
      return 'Listeleme';
    case 'show':
    case 'read':
      return 'Görüntüleme';
    default:
      return action;
  }
};

const RoleEdit: React.FC<RoleEditProps> = ({ id, onClose }) => {
  const [permissions, setPermissions] = useState<IPermissionResponseData[]>([]);
  const [groupedPermissions, setGroupedPermissions] = useState<{ [key: string]: IPermissionResponseData[] }>({});
  const [role, setRole] = useState<IRoleResponseData | null>(null);
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (role) {
      form.resetFields();
      form.setFieldsValue(role);
    }
  }, [role, form]);

  useEffect(() => {
    fetchPermissions();
    fetchRole();
  }, [id]);

  /**
   * Tüm izinleri çek, isim sırasına göre sırala ve grupla.
   */
  const fetchPermissions = () => {
    PermissionService.getAll()
      .then((response: any) => {
        const sortedData = [...response.data].sort((a, b) => a.name.localeCompare(b.name));
        setPermissions(sortedData);
        groupPermissions(sortedData);
      })
      .catch((error: Error) => {
        console.error('İzinler alınamadı', error);
      });
  };

  /**
   * Grup bazlı izinleri bir dictionary haline getirir.
   */
  const groupPermissions = (allPermissions: IPermissionResponseData[]) => {
    const grouped = allPermissions.reduce((acc, permission) => {
      const [group] = permission.name.split('.');
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(permission);
      return acc;
    }, {} as { [key: string]: IPermissionResponseData[] });

    setGroupedPermissions(grouped);
  };

  /**
   * İlgili rolü (id) çek ve mevcut seçili permission ID'lerini ayarla.
   */
  const fetchRole = () => {
    RoleService.get(id)
      .then((response: any) => {
        setRole(response.data);
        const ids: number[] = response.data.permissions.map((p: any) => p.id);
        setSelectedPermissions(ids);
        form.setFieldsValue(response.data);
      })
      .catch((error: Error) => {
        console.error('Rol alınamadı', error);
      });
  };

  /**
   * Tek bir checkbox değiştiğinde, selectedPermissions'ı günceller.
   */
  const onChange = (permissionId: number, checked: boolean) => {
    if (checked) {
      setSelectedPermissions([...selectedPermissions, permissionId]);
    } else {
      setSelectedPermissions(selectedPermissions.filter(pid => pid !== permissionId));
    }
  };

  /**
   * Form submit => role güncelleme isteği
   */
  const onFinish = (values: any) => {
    const updatedValues = {
      ...values,
      permissionIds: selectedPermissions,
    };
    RoleService.update(id, updatedValues)
      .then(() => {
        openNotification('success', 'Başarılı', 'Rol başarıyla güncellendi');
        onClose();
      })
      .catch((error: Error) => {
        openNotification('error', 'Başarısız', 'Rol güncellenirken hata oluştu');
        console.error('Rol güncellenemedi', error);
      });
  };

  if (!role) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <div className="role-edit-container">
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={role}
        form={form}
      >
        <Form.Item
          label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Adı</p>}
          name="name"
          rules={[{ required: true, message: 'Lütfen rol adını giriniz!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Yetkileri</p>}
          name="permissions"
        >
          <Divider style={{ marginTop: 16 }} />
          {Object.keys(groupedPermissions).map(group => {
            // Group'ları Türkçe'ye çevir
            const groupTr = translateGroup(group);

            return (
              <div key={group}>
                <Row gutter={[16, 16]} align="middle">
                  <Col span={5}>
                    <span style={{ fontWeight: 'bold' }}>{groupTr}</span>
                  </Col>

                  {groupedPermissions[group].map(permission => {
                    const actionPart = permission.name.split('.')[1]; // create, delete, etc.
                    const actionTr = translateAction(actionPart);
                    const isChecked = selectedPermissions.includes(permission.id);

                    return (
                      <span key={permission.id}>
                        <Checkbox
                          value={permission.id}
                          checked={isChecked}
                          onChange={e => onChange(permission.id, e.target.checked)}
                        >
                          {actionTr}
                        </Checkbox>
                      </span>
                    );
                  })}
                </Row>
                <Divider style={{ margin: '20px 0' }} />
              </div>
            );
          })}
        </Form.Item>

        <Form.Item>
          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              className="d-button width-auto dark-button"
            >
              Kaydet
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RoleEdit;