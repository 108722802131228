import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd'; // theme eklendi
import './App.scss';
import AuthLayout from './layouts/AuthLayout';
import Login from './views/Auth/Login';
import Register from './views/Auth/Register';
import EmailVerification from './views/EmailVerification';
import ForgotPassword from './views/Auth/ForgotPassword';
import MainLayout from './layouts/MainLayout';
import Dashboard from './views/Dashboard/Dashboard';
import Profile from './views/Profile/Profile';
import { RoleList } from './views/Role/RoleList';
import CreateRole from './views/Role/RoleCreate';
import RoleEdit from './views/Role/RoleEdit';
import { UserList } from './views/Users/UserList';
import PrivateRoute from './components/PrivateRoute';
import NotFoundPage from './views/NotFound';
import ForgotToNewPassword from './views/Auth/ForgotToNewPassword';
import EmailVerifySend from './views/Auth/EmailVerifySend';
import GoogleSign from './views/Auth/GoogleSign';
import ProposalCreate from './views/Proposal/ProposalCreate';
import Company from './views/Company/Companies';
import Vallet from './views/Vallet/Vallet';
import Tickets from './modules/Ticket/TicketList';
import Notes from './modules/Note/Notes';
import Video from './views/Video/Video';
import Statuses from './modules/Status/Statuses';
import CompanyDetail from './views/Company/CompanyDetail';
import Settings from './views/Settings/Settings';
import EmailUpdateVerification from './views/Profile/EmailUpdateVerification';
import TicketList from './modules/Ticket/TicketListAdmin';
import tr_TR from 'antd/es/locale/tr_TR';
import UserDetail from './components/User/UserDetail';
import LinkedinSign from './views/Auth/LinkedinSign';
import ProjectFollowUp from './views/ProjectFollowUp/ProjectFollowUp';
import ProposalList from './components/Proposal/ProposalList';
import AgreementCreate from './components/Agreements/AgreementCreate';
import AllNotificationsPage from './views/AllNotifications/AllNotifications';
import AssetsGallery from './views/AssetsGallery.tsx/AssetsGallery';

const customTheme = {
  token: {
    colorPrimary: `#${process.env.REACT_APP_PRIMARY_COLOR || '1677FF'}`, // Ana renk
    borderRadius: 8,
    fontFamily: 'Urbanist, sans-serif',
  },
  algorithm: theme.defaultAlgorithm,
  components: {
    Button: {
      fontWeight: 600,
    },
    Input: {},
  },
};

const ScrollToTop: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      console.log('ScrollToTop triggered for', location.pathname);
    }, 0);
  }, [location.pathname]);

  return null;
};

const App: React.FC = () => {
  return (
    <ConfigProvider theme={customTheme} locale={tr_TR}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/proposals" element={<ProposalList defaultStatusIds={[1, 2, 3, 10, 11]} />} />
            <Route path="/proposals/create" element={<ProposalCreate />} />
            <Route path="/agreements/create" element={<AgreementCreate />} />
            <Route path="/companies" element={<Company />} />
            <Route path="/companies/:id" element={<CompanyDetail />} />
            <Route path="/users/:id" element={<UserDetail />} />
            <Route path="/vallet" element={<Vallet />} />
            <Route path="/notes" element={<Video />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/all-tickets" element={<TicketList />} />
            <Route path="/statuses" element={<Statuses />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/all-notifications" element={<AllNotificationsPage />} />
            <Route path="/project-followup" element={<ProjectFollowUp />} />
            <Route path="/roles" element={<PrivateRoute element={<RoleList />} permission="roles.list" />} />
            <Route path="/users" element={<PrivateRoute element={<UserList />} permission="company.show" />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password-confirm" element={<ForgotToNewPassword />} />
            <Route path="/email-verify-check" element={<EmailVerifySend />} />
            <Route path="/oauth2/google/redirect-uri" element={<GoogleSign />} />
            <Route path="/oauth2/linkedin/redirect-uri" element={<LinkedinSign />} />
          </Route>
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/email-change-verification" element={<EmailUpdateVerification />} />
          <Route path="/secret-gallery" element={<AssetsGallery />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;