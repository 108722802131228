import { http } from "../http-common";

const getAllAnnouncements = (search?: string) => {
    const params = search ? { params: { search } } : {};
    return http.get<any[]>('/announcements', params);
};

const createAnnouncement = (data: any) => {
    return http.post<any>('/announcements', data);
};

const updateAnnouncement = (id: number, data: any) => {
    return http.put<any>(`/announcements/${id}`, data);
};

const deleteAnnouncement = (id: number) => {
    return http.delete(`/announcements/${id}`);
};

const AnnouncementService = {
    getAllAnnouncements,
    createAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
};

export default AnnouncementService;