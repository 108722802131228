import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Table, Button, Card, Input, Avatar, Popconfirm, Typography, Row, Col, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyCreate from "../../components/Companies/CompanyCreate";
import CompanyService from "../../services/CompanyService";
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import { capitalizeWords, formatDate, openNotification } from "../../utils";
import { debounce } from 'lodash';
import './Company.scss';

const { Title, Text } = Typography;

interface Company {
  id: string;
  name: string;
  authorizedPersons: [
    {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      default?: boolean;
    }
  ];
  email: string;
  phoneNumber: string;
  creationDate: string;
  imageId?: string;
  logoUrl?: string;
  account?: {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
  };
}

const Companies: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const storedPermissions = localStorage.getItem("permissions");
  const [permissions, setPermissions] = useState<string[]>(JSON.parse(storedPermissions ?? '')); // company.show kontrolü
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const userId = localStorage.getItem("id");
  const isPartner = localStorage.getItem("isPartner");

  const navigate = useNavigate();
  const location = useLocation();

  // Arama (debounce)
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value.length >= 1) {
        setSearchTerm(value);
        setCurrentPage(0);
      } else {
        setSearchTerm("");
        setCurrentPage(0);
      }
    }, 300),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLocaleLowerCase("tr-TR");
    debouncedSearch(value);
  };


  // Şirketleri çek
  useEffect(() => {
    fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerVisible, searchTerm, currentPage, pageSize, sortField, sortDirection]);

  const fetchCompanies = async () => {
    try {
      // Eğer "company.show" izni varsa => getAll
      // yoksa => getAllMe
      let fetchFn;
      console.log(permissions);
      console.log(permissions.includes("company.show"));
      if (permissions.includes("company.show")) {
        fetchFn = CompanyService.getAll;
      } else {
        fetchFn = CompanyService.getAllMe;
      }

      const response = await fetchFn({
        search: searchTerm,
        page: currentPage,
        size: pageSize,
        sortField,
        sortDirection
      });

      const companiesWithLogo = await Promise.all(
        response.data.content.map(async (company: Company) => {
          if (company.imageId) {
            try {
              const logoResponse = await CompanyService.getImage(company.imageId);
              return { ...company, logoUrl: logoResponse.data.preSignUrl };
            } catch (error) {
              console.error("Logo yüklenirken hata oluştu:", error);
            }
          }
          return company;
        })
      );
      setCompanies(companiesWithLogo);
      setFilteredCompanies(companiesWithLogo);
      setTotal(response.data.totalElements);
    } catch (error) {
      console.error("Şirketler yüklenirken hata oluştu:", error);
    }
  };

  // Tablo sayfa/sort değişimi
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setCurrentPage(pagination.current - 1);
    setPageSize(pagination.pageSize || 10);
    setSortField(sorter.field || "createdAt");
    setSortDirection(sorter.order === "ascend" ? "ASC" : "DESC");
  };

  // URL query param check
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const activeKey = queryParams.get("activeKey");
    if (activeKey === "create") {
      showDrawer();
    }
  }, [location.search]);

  // Teklifle ilgili navigasyon
  const handleOfferNavigation = (companyId: any) => {
    navigate(`/proposals/create?companyId=${companyId}`);
  };

  // Company detail
  const handleSendOffer = (companyId: string) => {
    navigate(`/companies/${companyId}`);
  };

  // Firma silme
  const handleDelete = async (companyId: string) => {
    try {
      await CompanyService.remove(companyId);
      openNotification("success", "Başarılı", "Firma başarıyla silindi");
      const updatedCompanies = companies.filter((c) => c.id !== companyId);
      setCompanies(updatedCompanies);
      setFilteredCompanies(updatedCompanies);
    } catch (error) {
      openNotification("error", "Hata", "Firma silinirken bir hata oluştu");
    }
  };

  // Drawer
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };
  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  // Tablo kolonları
  const columns: ColumnsType<Company> = [
    {
      title: "Firma Adı",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const displayText = text.length > 28 ? `${text.substring(0, 28)}...` : text;
        return (
          <div className="d-flex align-items-center">
            <div className="avatar-image-logo" style={{ marginRight: 10 }}>
              {record.logoUrl ? (
                <img
                  src={record.logoUrl}
                  alt={record.name}
                  className="avatar-image-logo"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <Avatar
                  className="avatar-image-logo"
                  style={{ backgroundColor: "#f0f0f0", color: "#000" }}
                >
                  {record.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>
            <Tooltip title={text.toUpperCase()}>
              <span>{displayText.toUpperCase()}</span>
            </Tooltip>
          </div>
        );
      },
    },
    // Eğer "company.show" izni varsa => "İş Ortağı ..." kolonları
    // yoksa => "Yetkili ..." kolonları
    ...(permissions.includes("company.show")
      ? [
        {
          title: "İş Ortağı Ad Soyad",
          key: "accountOwner",
          render: (text: any, record: any) =>
            record.account
              ? `${record.account.firstName} ${record.account.lastName}`
              : "Bilgi Yok",
        },
        {
          title: "İş Ortağı E-posta",
          key: "accountEmail",
          render: (text: any, record: any) =>
            record.account ? record.account.email : "Bilgi Yok",
        },
        {
          title: "İş Ortağı Numara",
          key: "accountPhone",
          render: (text: any, record: any) =>
            record.account ? record.account.phoneNumber : "Bilgi Yok",
        },
      ]
      : [
        {
          title: "Yetkili Ad Soyad",
          key: "authorizedPersons",
          render: (text: any, record: any) => {
            const defaultPerson =
              record.authorizedPersons.find((p: any) => p.default) ||
              record.authorizedPersons[0];
            return defaultPerson
              ? `${capitalizeWords(defaultPerson.firstName)} ${capitalizeWords(
                defaultPerson.lastName
              )}`
              : "Yetkili Bulunamadı";
          },
        },
        {
          title: "Yetkili E-posta",
          dataIndex: "email",
          key: "email",
          render: (text: any, record: any) => {
            const defaultPerson =
              record.authorizedPersons.find((p: any) => p.default) ||
              record.authorizedPersons[0];
            return defaultPerson ? defaultPerson.email : "Yetkili E-posta Bulunamadı";
          },
        },
        {
          title: "Yetkili Cep Telefonu",
          dataIndex: "phoneNumber",
          key: "phoneNumber",
          render: (text: any, record: any) => {
            const defaultPerson =
              record.authorizedPersons.find((p: any) => p.default) ||
              record.authorizedPersons[0];
            return defaultPerson ? defaultPerson.phoneNumber : "Yetkili Telefon Bulunamadı";
          },
        },
      ]),
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text) => <Text>{formatDate(text)}</Text>,
    },
    {
      title: "İşlem",
      key: "action",
      align: "center",
      width: 200,
      render: (text, record: any) => (
        <div className="companies-action">
          <Tooltip
            title={
              isPartner?.toString() === "false"
                ? "Teklif oluşturmak için iş ortağı sözleşmesini onaylamanız gerekmektedir"
                : ""
            }
          >
            <Button
              type="default"
              className="action-button"
              onClick={() => handleOfferNavigation(record.id)}
              disabled={permissions.includes('company.show') ? (record.account && record.account.id != userId) : (record.account && record.account.id != userId )|| isPartner == "false" }
            >
              Teklif Oluştur
            </Button>
          </Tooltip>
          <Button
            icon={<img src={PenIcon} alt="pen" className="icon" />}
            shape="circle"
            onClick={() => handleSendOffer(record.id)}
            className="icon-button ml-12px"
          />
          <Popconfirm
            title="Bu firmayı silmek istediğinize emin misiniz?"
            onConfirm={() => handleDelete(record.id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button
              icon={<img src={TrashIcon} alt="trash" className="icon" />}
              shape="circle"
              className="icon-button ml-12px"
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const pagination = useMemo(
    () => ({
      current: currentPage + 1,
      pageSize: pageSize,
      total: total,
      onChange: (page: number, pageSize?: number) => {
        setCurrentPage(page - 1);
        setPageSize(pageSize || 10);
      },
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "30"],
    }),
    [currentPage, pageSize, total]
  );

  return (
    <Card className="company-card br-30px mb-120px">
      <Row gutter={[16, 16]} align="middle" justify="space-between" className="mb-20px">
        <Col xs={24} sm={12} lg={16}>
          <Title level={4} style={{ margin: 0 }}>
            Firma Listesi
          </Title>
        </Col>
        <Col
          xs={24}
          sm={12}
          lg={8}
          style={{ textAlign: "center" }}
          className="d-flex justify-content-end"
        >
          <Input
            placeholder="Ara"
            prefix={<SearchOutlined style={{ color: "#b8b9bc" }} />}
            className="d-input d-input-filter"
            style={{ width: "100%", maxWidth: 200, marginRight: 8 }}
            onChange={handleSearch}
          />
          <Button
            type="primary"
            onClick={showDrawer}
            className="d-button dark-button width-auto br-30px"
          >
            Yeni Firma Ekle
          </Button>
        </Col>
      </Row>

      <Table
        scroll={{ x: 768 }}
        columns={columns}
        dataSource={filteredCompanies}
        rowKey="id"
        pagination={pagination}
        onChange={handleTableChange}
      />

      {/* Şirket Oluştur / Güncelleme */}
      <CompanyCreate visible={isDrawerVisible} onClose={closeDrawer} />
    </Card>
  );
};

export default Companies;